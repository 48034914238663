<template>
  <div id="creditOrDebitDeposit">
    <div class="content_box">
      <div class="inner">
        <component :is="currentComponent"></component>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'creditOrDebit',
  computed: {
    currentComponent() {
      return () => this.$config.pageLoader(this.regulator, 'CreditCard');
    }
  }
};
</script>
